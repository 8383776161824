body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root {
    flex-grow: 1;
}

body {
    padding-top: 123px;
}

#nav .navbar {
    /* height: 75px; */
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

nav.product-bar {
    top: 75px;
}

/* ------------------------------------------------------------------
 * Override some reactware styles.
 *
 * Eventually, we'll likely want to remove the reactware components, but we
 * don't need that yet.
 */

/* BrandBar styles ported from Reactware */
.BrandBar {
    position: absolute;
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif;
    text-transform: lowercase;
    left: 219px;
    top: 41px;
}

/* ProductBar styles. */
.pageContainer > nav:first-child {
    margin-top: 75px;
    min-height: unset;
    padding: 0 16px;
    background-color: rgba(50,136,188,0.95);
    box-shadow: unset;
    height: 48px;
}

/* ProductBar .nav-item styles */
.pageContainer > nav:first-child .navbar-nav .nav-item {
    padding: 4px;
    font-size: inherit;
    margin-left: 4px !important;
    line-height: 24px;
}

/* ProductBar .nav-item styles */
.pageContainer > nav:first-child .navbar-nav .nav-item .fa {
    margin-right: 5px;
}

/* ProductBar .nav-link styles */
.pageContainer > nav:first-child .navbar-nav .nav-link {
    padding: 0.5rem !important;
}

/* ------------------------------------------------------------------ */
/* Copied from www.alces-flight.com to style account menu / brand bar */

.navbar-brand img {
    max-height: 50px;
}

#nav .navbar {
    height: 75px;
}

#nav .navbar, .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.9);
}

#nav .navbar .dropdown-toggle:after {
    align-self: center;
    margin-left: 5px;
    display: none;
}

#nav .navbar #nav-menu-more:after {
    display: block;
}

#nav nav a.nav {
    font-size: 12px;
    color: white;
}
#nav nav a.nav.dropdown-item:hover {
    background-color: #586775;
}

a.nav.nav-separator {
    border-right: 1px dotted #586775;
}

#nav nav .btn {
    border-radius: 25px;
    font-size: 14px;
    font-weight: bold;
}

.flight-account-menu > button {
    margin-top: 0;
}

.flight-account-menu > button i {
    margin-right: 5px;
}

.flight-account-menu .user-name--null .user-name + br {
    display: none;
}

.flight-account-menu .user-avatar > img {
    width: 36px;
    border-radius: 5px;
}

.flight-account-menu .dropdown-menu {
    text-transform: uppercase;
}

footer {
    background-color: rgb(0, 0, 0);
    color: white;
    font-size: 14px;
}

footer #footer-nav .row {
    margin: 0;
}

footer a.nav {
    padding: 0 10px 0 10px !important;
}

footer span.copyright {
    padding: 0 10px 0 10px !important;
    font-weight: bold;
}


footer a {
    color: #cae4f6;
    text-decoration: underline;
}

footer a.btn {
    text-decoration: none;
}

footer a:hover {
    color: white;
}
