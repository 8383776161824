.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

.app-warning {
    position: fixed;
    z-index: 10000;
    bottom: 41px;
    width: 100%;
    text-align: center;
    background: rgba(255,128,0,0.5);
    min-height: 60px;
    font-size: 25px;
    line-height: 60px;
    text-shadow: 0 0 10px white, 0 0 10px white, 0 0 10px white, 0 0 10px white;
    color: black;
    font-weight: bold;
}
